.adminToolContentContainer {
  width: inherit;
  display: flex;
  flex-direction: row;
}

.disableFields {
  color: #949494;
}

.enabledFields {
  color: #262626;
}

.adminToolContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 100px;
  gap: 20px;
}

.adminToolContent p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

.adminToolSettings {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 50px 100px;
  gap: 20px;
}

.adminToolSettings p {
  font-size: 14px;
  line-height: 20px;
}

.adminAbuContinuityAdvanceSettings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.adminToolForm {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.advanceFunctionalitySetting {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -20px;
  gap: 10px;
}

.deactivationButtonStyle {
  margin-left: 20px;
  font-size: 14px;
  color: #ffffff;
  padding: 8px 12px;
  background: #cc0000;
  border-radius: 2px;
  border: none;
  cursor: pointer;
}

.adminContinuityToolSettings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ContinuityToolContentSettings {
  font-size: 20px;
}

.customerLoginForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: -20px;
}

.invalidInputStyle {
  color: #cc0000;
  font-size: 12px;
  padding-left: 47px;
}

.abuContinuitySaveButton {
  border: none;
  width: fit-content;
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 2px;
}

.enabledSaveButton {
  color: #ffffff;
  background-color: #0071c2;
}

.disableSaveButton {
  background-color: #d9d9d9;
  color: #949494;
}
