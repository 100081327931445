.adminToolAlertContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  width: 40%;
  margin: 20px auto auto auto;
}

.adminToolSuccessAlertStyle {
  border: 1px solid #008009;
  border-radius: 2px;
  background: #e7fde9;
}

.adminToolErrorAlertStyle {
  background: #fff0f0;
  border: 1px solid #cc0000;
  border-radius: 2px;
}

.VectorIconImg {
  width: 20px;
  height: 20px;
}

.adminToolAlertContentContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.alertContentHeaderText {
  font-size: 16px;
  color: #262626;
}

.alertContentText {
  font-size: 14px;
  color: #262626;
}
