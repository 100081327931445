:root {
  --main-color: #003580;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
}
