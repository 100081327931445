.adminTelephone {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 47px;
}

.adminTelephoneInput {
  border-radius: 2px;
  border: 1px solid #949494;
  box-sizing: border-box;
  height: 40px;
  width: 384px;
}
.adminTelephone label {
  font-size: 14px;
}

.invalidInputStyle {
  color: #cc0000;
  font-size: 12px;
}

.errorInputFieldStyle {
  border: 1px solid #cc0000;
}
