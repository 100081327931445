.adminToolContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 50px 100px;
  gap: 20px;
}

.adminToolContent p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

.linkSplashPagesContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.linkSplashPages {
  color: #0071c2;
  cursor: pointer;
}

.linkSplashPages a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
