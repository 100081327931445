.confirmationContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 60px;
}

.confirmationContentHeaderText {
  font-size: 24px;
}

.confirmationContentText {
  font-size: 14px;
}

.adminToolConfigContainer {
  display: flex;
  flex-direction: row;
  font-size: 16px;
}

.adminToolConfigContentContainer {
  width: 50%;
  font-size: 16px;
}

.adminToolStateContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 50%;
  font-size: 16px;
}

.adminToolTelephoneNumberStateContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.VectorForwardArrowImg {
  width: 20px;
  height: 20px;
}

.confirmationContentButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.confirmationButton {
  background: #0071c2;
  border-radius: 2px;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.cancelButton {
  border: 1px solid #0071c2;
  border-radius: 2px;
  padding: 8px 12px;
  color: #0071c2;
  cursor: pointer;
}
