.header {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.bookingLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adminHeaderAndLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  gap: 20px;
}

.adminSignoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.adminSignoutButtonStyle {
  border: none;
  background: none;
  color: #0071c2;
  font-size: 14px;
  cursor: pointer;
}

.vectorSignoutImgStyle {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.bookingContinuityStatus {
  color: #262626;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.bookingContinuityStatusContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 8rem;
  margin-top: 5.5rem;
  margin-bottom: 7.5rem;
}

.enabledAdminToolStatus {
  color: #008009;
}

.disabledAdminToolStatus {
  color: #6b6b6b;
}
