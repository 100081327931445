.pos-center {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loader {
  border: 10px solid #003580;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
